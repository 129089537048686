<template>
  <el-dialog
    class="m-safety-dialog"
    :visible.sync="showPcSafety"
    width="88%">
    <div class="m-safety-dialog-content">
      <img class="safety-close" @click="showPcSafety = false" src="../assets/img/team/close-dialog.png" alt="close">
      <img class="m-safety-icon" src="../assets/img/common/m-safety.png" alt="safety">
      <div class="m-safety-tip">
        Bantusaku tidak pernah meminta sejumlah uang atau deposit kepada calon meminjam sebagai syarat untuk memproses pengajuan pinjaman
      </div>
      <div class="m-safety-text">Jika anda membutuhkan bantuan, hubungi kami di :</div>
      <div class="m-safety-content">
        <div class="safety-item">
          <img src="../assets/img/common/tel.png" alt="tel">
          <div>Telepon: 1500-006</div>
        </div>
        <div class="safety-item">
          <img src="../assets/img/common/watsapp.png" alt="watsapp">
          <div>Whatsapp: 0889-1500-006</div>
        </div>
        <div class="safety-item">
          <img src="../assets/img/common/sms.png" alt="sms">
          <div>Email: cs@bantusaku.id</div>
        </div>
      </div>
      <div class="m-safety-bottom-tip">
        <div class="start-tip">HATI-HATI, TRANSAKSI INI BERESIKO TINGGI. ANDA DAPAT SAJA MENGALAMI KERUGIAN ATAU KEHILANGAN UANG.</div>
        <div>JANGAN BERUTANG JIKA TIDAK MEMILIKI KEMAMPUAN MEMBAYAR. PERTIMBANGKAN SECARA BIJAK SEBELUM BERTRANSAKSI.</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showPcSafety: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.m-safety-dialog-content{
  background: linear-gradient(180deg, #DBE6E1 0%, #FFFFFF 50%);
  border-radius: 14px;
  position: relative;
  padding: 56px 14px 18px 14px;
  box-sizing: border-box;
  font-weight: 600;
  .safety-close{
    width: 26px;
    height: 26px;
    position: absolute;
    right: 13px;
    top: -36px;
    cursor: pointer;
  }
  .m-safety-icon{
    position: absolute;
    width: 188px;
    height: 196px;
    left: 50%;
    top: -94px;
    margin-left: -94px;
  }
  .m-safety-tip{
    font-size: 13px;
    color: #1E6642;
    line-height: 21px;
  }
  .m-safety-text{
    font-size: 13px;
    margin: 26px 0 14px 0;
  }
  .m-safety-content{
    .safety-item{
      display: flex;
      font-size: 12px;
      font-weight: 600;
      img{
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .safety-item:nth-child(2){
      margin: 15px 0;
    }
    
  }
  .m-safety-bottom-tip{
    width: 100%;
    background: url("../assets/img/home/m-safe-tip-banner.png") no-repeat;
    background-size: 100% 100%;
    margin-top: 10px;
    padding: 12px 10px 14px 16px;
    box-sizing: border-box;
    font-size: 9px;
    .start-tip{
      padding-left: 44px;
      margin-bottom: 6px;
    }
  }
}
</style>