<template>
  <div class="home-wrap">
    <img class="kf-btn" src="../assets/img/home/pc-kf.png" alt="kf" @click="showFeedbackDialog">
    <div class="home-calculate">
      <div class="calculate-banner hidden-xs-only wow animate__fadeInLeft">
        <div class="calculate-banner-content">
          <!-- <img class="banner-content-tip" src="../assets/img/home/ProsesMudahDanCepat.png" alt="ProsesMudahDanCepat"> -->
          <div class="banner-tip">Proses Mudah Dan Cepat <span>dengan keamanan</span> Yang Tinggi</div>
          <div class="content-recommend">
            <div class="recommend-item">
              <img src="../assets/img/home/success.png" alt="success">
              <div>Ketersediaan limit yang dapat dipakai dan dilunasi kapanpun</div>
            </div>
            <div class="recommend-item">
              <img src="../assets/img/home/success.png" alt="success">
              <div>Pengajuan mudah & pembayaran fleksibel</div>
            </div>
            <div class="recommend-item">
              <img src="../assets/img/home/success.png" alt="success">
              <div>Kenaikan limit kredit, potongan terus-menerus</div>
            </div>
          </div>
          <div class="calculate-down">
            <img class="wow animate__bounceInUp" @click="downApp('android')" src="../assets/img/home/google-pay.png" alt="google" />
            <img class="wow animate__bounceInUp" @click="downApp('ios')" src="../assets/img/home/ios-app.png" alt="ios" />
          </div>
        </div>
      </div>
      <div class="calculate-card wow animate__fadeInRight">
        <div class="calculate-content-tip-box">
            <div class="m-banner-tip hidden-sm-and-up">Proses Mudah Dan Cepat <span>dengan keamanan</span> Yang Tinggi</div>
        </div>
        <div class="calculate-content">
          <div class="calculate-amount-box">
            <div class="amount-tip">Jumlah Pinjaman</div>
            <div class="main-amount">
              <span>Rp</span>
              <animate-number
                from="0"
                :to="amount"
                :comma="true"
                :key="amount"
                duration="500"
                easing="easeOutQuad"
                :formatter="formatter"
                class="amount" />
          </div>
          </div>
          <div class="calculate-slider-box">
            <el-slider
              class="calculate-slider"
              :show-tooltip="false"
              v-model="sliderValue"
              :min="1000000"
              :max="20000000"
              :step="1000000"
              @input="changeSlider">
            </el-slider>
            <div class="slider-quota">
              <div>1.000.000</div>
              <div>20.000.000</div>
            </div>
          </div>
          <div class="calculate-detail-box">
            <div class="detail-left">
              <div class="detail-tip">Lama Pinjaman</div>
              <div class="period">90 hari</div>
            </div>
            <div class="detail-right">
              <div class="detail-tip">Bunga Per Hari</div>
              <div class="rate">0.3%</div>
            </div>
          </div>
          <div class="calculate-bottom-tip">*Proses Pinjaman Cepat</div>
          <div class="calculate-btn-box">
            <div class="calculate-btn" @click="goLoan">Lihat limit saya ></div>
          </div>
        </div>
        <div class="content-recommend hidden-sm-and-up">
            <div class="recommend-item">
              <img src="../assets/img/home/success.png" alt="success">
              <div>Ketersediaan limit yang dapat dipakai dan dilunasi kapanpun</div>
            </div>
            <div class="recommend-item">
              <img src="../assets/img/home/success.png" alt="success">
              <div>Pengajuan mudah & pembayaran fleksibel</div>
            </div>
            <div class="recommend-item">
              <img src="../assets/img/home/success.png" alt="success">
              <div>Kenaikan limit kredit, potongan terus-menerus</div>
            </div>
          </div>
      </div>
    </div>
    <div class="home-serve wow animate__fadeInUp">
      <div class="calculate-down hidden-sm-and-up">
            <img src="../assets/img/home/google-pay.png" alt="google" @click="downApp('android')" />
            <img src="../assets/img/home/ios-app.png" alt="ios" @click="downApp('ios')" />
          </div>
      <div class="serve-tip">
        Bantusaku Bisa menyediakan layanan apa saja?
        <img src="../assets/img/home/serve-item1.png" alt="serve">
      </div>
      <div class="serve-item-box">
        <el-row :gutter="0">
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="grid-content wow animate__fadeInUp">
              <div class="item-card">
                <img src="../assets/img/home/serve-item2.png" alt="lock"/>
                <div>Bantu Belanja</div>
              </div>
              <div class="grid-bottom-tip">Membeli kebutuhan bulanan tanpa pusing dengan BantuSaku.</div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="grid-content wow animate__fadeInUp">
              <div class="item-card">
                <img src="../assets/img/home/serve-item3.png" alt="lock"/>
                <div>Bantu Liburan</div>
              </div>
              <div class="grid-bottom-tip">Memenuhi keinginan staycation dan jalan-jalanmu dengan BantuSaku.</div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="grid-content wow animate__fadeInUp">
              <div class="item-card">
                <img src="../assets/img/home/serve-item4.png" alt="lock"/>
                <div>Bantu Usaha</div>
              </div>
              <div class="grid-bottom-tip">Mantapkan keinginan berbisnismu sekarang dengan tambahan modal dari BantuSaku.</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="business-data wow animate__fadeInLeft">
      <div class="business-item-box">
        <el-row :gutter="0">
          <el-col :xs="12" :sm="12" :md="8" :lg="{span: '24-5'}" :xl="{span: '24-5'}">
            <div class="business-grid-content">
                <div class="num">6.94</div>
                <div class="unit">Triliun</div>
                <div class="data">Total Pinjaman Tahun ini</div>
                <div class="bottom-line"></div>
              </div>
          </el-col>
          <el-col :xs="12" :sm="12" :md="8" :lg="{span: '24-5'}" :xl="{span: '24-5'}">
            <div class="business-grid-content">
                <div class="num">19.75</div>
                <div class="unit">Triliun</div>
                <div class="data">Total Pinjaman Sejak Awal</div>
                <div class="bottom-line"></div>
              </div>
          </el-col>
          <el-col :xs="12" :sm="12" :md="8" :lg="{span: '24-5'}" :xl="{span: '24-5'}">
            <div class="business-grid-content">
                <div class="num">1.23</div>
                <div class="unit">Triliun</div>
                <div class="data">Total Pinjaman yang belum dibayar</div>
                <div class="bottom-line"></div>
            </div>
          </el-col>
          <el-col :xs="12" :sm="12" :md="8" :lg="{span: '24-5'}" :xl="{span: '24-5'}">
            <div class="business-grid-content">
                <div class="num">238</div>
                <div class="unit">Ribu</div>
                <div class="data">Total Peminjam aktif</div>
                <div class="bottom-line"></div>
            </div>
          </el-col>
          <el-col :xs="12" :sm="12" :md="8" :lg="{span: '24-5'}" :xl="{span: '24-5'}">
            <div class="business-grid-content">
                <div class="num">1.51</div>
                <div class="unit">Juta</div>
                <div class="data">Total Peminjam</div>
                <div class="bottom-line"></div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="tutorial wow animate__fadeInUp">
      <div class="tutorial-item-box wow animate__pulse">
        <el-row :gutter="0">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="tutorial-grid-content">
              <img class="tutorial-left" src="../assets/img/home/tutorial1.png" alt="tutorial1">
              <div class="tutorial-right">
                <img class="right-tip" src="../assets/img/home/tutorial5.png" alt="" />
                <div class="right-top">Proses Pengajuan Cepat dan Mudah</div>
                <div class="right-text">Pengajuan Pinjaman Dana yang Cepat dan Mudah hanya dalam satu genggaman Smartphone. Hanya menggunakan KTP dan Rekening Pribadi.</div>
              </div>
            </div>  
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="tutorial-grid-content">
              <img class="tutorial-left" src="../assets/img/home/tutorial2.png" alt="tutorial1">
              <div class="tutorial-right bg-darks">
                <img class="right-tip" src="../assets/img/home/tutorial6.png" alt="" />
                <div class="right-top">Jumlah Produk Variatif</div>
                <div class="right-text">Variatif produk yang disediakan oleh BantuSaku sangat membantu para calon peminjam, memilih jumlah produk pinjaman sesuai dengan kebutuhan mereka.</div>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="tutorial-grid-content">
              <img class="tutorial-left" src="../assets/img/home/tutorial3.png" alt="tutorial1">
              <div class="tutorial-right bg-darks">
                <img class="right-tip" src="../assets/img/home/tutorial7.png" alt="" />
                <div class="right-top">Waktu Pembayaran Pinjaman Fleksibel</div>
                <div class="right-text">Tenor Panjang & Sangat fleksibel Total hingga 90 hari waktu pengembalian pinjaman.</div>
              </div> 
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="tutorial-grid-content">
              <img class="tutorial-left" src="../assets/img/home/tutorial4.png" alt="tutorial1">
              <div class="tutorial-right">
                <img class="right-tip" src="../assets/img/home/tutorial8.png" alt="" />
                <div class="right-top">Aman dan Terpercaya</div>
                <div class="right-text">Aplikasi BantuSaku dilengkapi dengan teknologi dan sistem manajemen resiko canggih yang memanfaatkan teknologi, algoritma, dan sumber data yang eksklusif.</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="loan-wrap wow animate__fadeInLeft">
      <div class="loan-item-box">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="loan-grid-content wow animate__fadeInLeft">
              <div class="loan-grid-head">
                <img src="../assets/img/home/loan-item1.png" alt="loan" />
                <div>Informasi Pinjaman Anda</div>
              </div>
              <div class="loan-item">
                <div class="loan-item-title">Pokok Pinjaman</div>
                <div class="loan-item-text">Rp 1.000.000</div>
              </div>
              <div class="loan-item">
                <div class="loan-item-title">Bunga Per Hari</div>
                <div class="loan-item-text">0.3%</div>
              </div>
              <div class="loan-item">
                <div class="loan-item-title">Jangka Waktu Angsuran</div>
                <div class="loan-item-text">90 Hari</div>
              </div>
            </div>  
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="loan-grid-content wow animate__fadeInRight">
              <div class="loan-grid-head">
                <img src="../assets/img/home/loan-item2.png" alt="loan" />
                <div>Informasi Angsuran Anda</div>
              </div> 
              <div class="loan-item">
                <div class="loan-item-title">Total Bunga</div>
                <div class="loan-item-text">Rp 270.000</div>
              </div>
              <div class="loan-item">
                <div class="loan-item-title">Total Dibayarkan</div>
                <div class="loan-item-text">Rp 1.270.000</div>
              </div>
            </div>  
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="feedback-wrap wow animate__fadeInUp">
      <div class="feedback-card">
        <img src="../assets/img/home/kf-banner.png" alt="kf">
        <el-carousel class="feedback-carousel" indicator-position="none">
          <el-carousel-item>
            <div class="feedback-text">"Aplikasi yang sederhana dan praktis, terima kasih BantuSaku telah memberi kesempatan saya untuk ngajuin pinjaman, berharap semoga di setujui. Kalo di setujui,saya akan tetap menjaga kepercayaan yg di berikan pada saya dengan cara selalu komit untuk bayar tepat waktu. Semoga BantuSaku semakin sukses,amin"</div>
            <div class="feedback-prople">- Oni Budiharto -</div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="feedback-text">"Saya sangat membutuhkan pinjaman, dan Play Store memberi saya beberapa dan ketika saya sampai di sini saya membaca beberapa peringkat dan komentar, yang membujuk saya untuk mencobanya, dan dapatkah Anda percaya bahwa dalam waktu kurang dari 5 menit Saya mendapatkan pinjaman saya, cepat dan mudah, tanpa terlalu banyak cerita ... sederhana dan efisien, Anda pantas mendapatkan peringkat bintang lima ini"</div>
            <div class="feedback-prople">- yoshi suzanne Christian -</div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="feedback-text">"Trima kasih prifikasih antas nama Ika sartika sudah gagal, saya hapus ya aplikasinya, tolong di jaga baik2 data saya, saya tidak jadi minjm alhmdulilah sudah dapat dari sodara, ttp saya kasih bintang 🌟🌟🌟🌟🌟, karna ngisi datanya gampang, sukses selalu".</div>
            <div class="feedback-prople">- yoshi suzanne Christian -</div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="feedback-bottom">
        <img src="../assets/img/home/start.png" alt="star">
        <div>Ulasan Pelanggan Kami</div>
      </div>
    </div>
    <div class="lender-wrap hidden-xs-only wow animate__fadeInUp">
      <div class="lender-card">
        <img class="rp-img" src="../assets/img/home/rp.png" alt="rp">
        <div class="lender-center">
          <img src="../assets/img/home/sm-google.png" alt="sm-google">
          <div class="center-t">BANTUSAKU LENDER</div>
          <div class="center-c">Kelola keuanganmu dengan cara terat</div>
          <div class="center-b">
            <div>Mudah</div>
            <div>Cepat</div>
            <div>Terjangkau</div>
          </div>
        </div>
        <div class="lender-right">
          <div>Jaminan Uang Kembali Dalam 5 Bulan</div>
          <div class="lender-btn" @click="goLender">Lihat detail -></div>
        </div>
      </div>
    </div>
    <div class="m-lender-wrap hidden-sm-and-up">
      <div class="m-lender-card">
          <div class="m-lender-title">BANTUSAKU LENDER</div>
          <div class="m-lender-tip">Kelola keuanganmu dengan cara terat</div>
          <div class="m-lender-center">
            <div>Mudah</div>
            <div>Cepat</div>
            <div>Terjangkau</div>
          </div>
          <div class="m-lender-bottom">Jaminan Uang Kembali Dalam 5 Bulan</div>
          <div class="m-lender-btn" @click="goLender">Lihat detail -></div>
      </div>
    </div>
    <div class="partner-wrap wow animate__fadeInUp">
      <div class="partner-tip">Mitra Kami</div>
      <div class="partner-center">
        <el-carousel class="partner-silder" height="156px">
          <el-carousel-item class="partner-silder-item">
            <img src="../assets/img/home/partner1.png" alt="partner">
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/img/home/partner2.png" alt="partner">
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/img/home/partner3.png" alt="partner">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="auth-wrap hidden-xs-only wow animate__fadeInUp">
      <div class="auth-card">
        <el-row :gutter="0">
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="auth-content">
              <div>Berizin & Diawasi Oleh :</div>
              <img src="../assets/img/home/ojk.png" alt="ojk">
              <div class="cek-text" @click="check('ojk')">Cek Disini</div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="auth-content">
              <div>Terdaftar di :</div>
              <img src="../assets/img/home/afpi.png" alt="afpi">
              <div class="cek-text" @click="check('afpi')">Cek Disini</div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="auth-content">
              <div>Information Security Management System :</div>
              <img src="../assets/img/home/kan.png" alt="cb" class="kan">
              <div class="cek-text" @click="check('cb')">Cek Disini</div>
            </div>
          </el-col>
        </el-row> 
      </div>
    </div>
    <div class="m-auth-wrap hidden-sm-and-up">
      <div class="m-auth-item">
        <div class="m-auth-left">
          <div class="m-auth-title">Berizin & Diawasi Oleh :</div>
          <div class="m-auth-text"  @click="check('ojk')">（Cek Disini）</div>
        </div>
        <div class="m-auth-right">
          <img src="../assets/img/home/m-ojk.png" alt="m-ojk">
        </div>
      </div>
      <div class="m-auth-item">
        <div class="m-auth-left">
          <div class="m-auth-title">Terdaftar di :</div>
          <div class="m-auth-text"  @click="check('afpi')">（Cek Disini）</div>
        </div>
        <div class="m-auth-right">
          <img src="../assets/img/home/m-afpi.png" alt="m-afpi">
        </div>
      </div>
      <div class="m-auth-item">
        <div class="m-auth-left">
          <div class="m-auth-title">Information Security Management System :</div>
          <div class="m-auth-text"  @click="check('cb')">（Cek Disini）</div>
        </div>
        <div class="m-auth-right">
          <img src="../assets/img/home/kan.png" alt="m-cb" class="m-kan">
        </div>
      </div>
    </div>
    <safetyDialog 
      ref="pcSafety"
      v-model="showPcSafety"/>
    <mSafetyDialog 
      ref="mSafet"
      v-model="showMSafety"/>
    <feedbackDialog 
      ref="feedbackDialog"
      v-model="showPcFeedback"/>
      <mfeedbackDialog 
      ref="mfeedbackDialog"
      v-model="showMFeedback"/>
  </div>
</template>

<script>
import { isPC } from '../utils/auth'
import safetyDialog from '../components/safety-dialog'
import mSafetyDialog from '../components/m-safety-dialog'
import feedbackDialog from '../components/feedback-dialog'
import mfeedbackDialog from '../components/m-feedback-dialog'
import {WOW} from 'wowjs'
export default {
  name: 'Home',
  components: {
    safetyDialog, mSafetyDialog, feedbackDialog,mfeedbackDialog
  },
  data(){
    return{
      isPC: true,
      amount: 1000000,
      sliderValue: 1000000,
      showPcSafety: false,
      showMSafety: false,
      showPcFeedback: false,
      showMFeedback: false
    }
  },
  mounted(){
    this.$nextTick(() => {
      var wow = new WOW({
        animateClass: 'animated',
        offset: 0,
      })
      wow.init()
    })
    this.showSafety()
  },
  methods:{
    changeSlider(e){
      this.amount = e
    },
    formatter(num){
      return num.toLocaleString().replace(/,/g,".")
    },
    goLoan(){
      location.href = "https://m.bantusaku.id"
    },
    goLender(){
      sessionStorage.setItem('activeTab', '2')
      this.$router.push('/lender')
    },
    downApp(type){
      if(type == 'ios'){
        window.open('https://apps.apple.com/id/app/bantusaku-pinjaman-online-ojk/id1619483828')
      } else {
        window.open('https://play.google.com/store/apps/details?id=com.smartec.ft')
      }
    },
    showFeedbackDialog(){
      if (isPC() == false) {
          this.showMFeedback = true
        } else {
          this.showPcFeedback = true
        }
    },
    check(type){
      if(type == 'ojk'){
        window.open('https://drive.google.com/file/d/1WGs3a1CsLSixNC86EUB6isFB2BZlP3sZ/view?usp=drive_link')
      } else if(type == 'afpi') {
        window.open('https://drive.google.com/file/d/1o3oLbprXkB8boVNRH2kyK9Zc0ddp3dAs/view?usp=drive_link')
      } else {
        window.open('https://drive.google.com/file/d/1kbSXtHpUe15aYNJuEGV4Flp4FdIPqLBw/view?usp=drive_link')
      }
    },
    showSafety(){
      sessionStorage.setItem('activeTab', '1')
      if(!sessionStorage.getItem('safety')){
        sessionStorage.setItem('safety',true)
        if (isPC() == false) {
          this.isPC = false
          this.showMSafety = true
        } else {
          this.isPC = true
          this.showPcSafety = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home-wrap{
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  .kf-btn{
      width: 80px;
      height: 80px;
      position: fixed;
      right: 4%;
      bottom: 14%;
      cursor: pointer;
      z-index: 99;
    }
  .home-calculate{
    width: 100%;
    padding-bottom: 40px;
    box-sizing: border-box;
    background: #084032;
    display: flex;
    align-content: center;
    .calculate-banner{
      flex: 1;
      height: 100%;
      background: url("../assets/img/home/calculate-banner-bg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      .calculate-banner-content{
        width: 64%;
        margin-top: 18%;
        margin-bottom: 90px;
        .banner-content-tip{
          width: 100%;
        }
        .banner-tip{
          font-size: 40px;
          width: 100%;
          color: #fff;
          font-weight: 700;
          span{
            color: #B3E872;
          }
        }
      }
    }
    .calculate-card{
      flex: 1;
      height: 100%;
      background: #084032;
      .calculate-content-tip-box{
        padding-top: 20px;
      }
      .calculate-content-tip{
        width: 80%;
      }
      .m-banner-tip{
        font-size: 30px;
        color: #fff;
        padding: 0 34px 0 34px;
        font-weight: 700;
        span{
          color: #B3E872;
        }
      }
      .calculate-content{
        width: 82%;
        min-height: 786px;
        background: url("../assets/img/home/calculate-bg.png") no-repeat;
        background-color: #FAFAFA;
        background-size: 100% 77%;
        border-radius: 28px;
        margin: 5% 0 0 30px;
        padding: 60px 34px 0 34px;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        .calculate-amount-box{
          width: 100%;
          display: flex;
          padding: 0 5% 0 4%;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          .amount-tip{
            font-size: 20px;
            font-weight: 600;
          }
          .main-amount{
            font-size: 34px;
            color: #084032;
            font-weight: 600;
          }
        }
        .calculate-slider-box{
          width: 100%;
          padding: 0 5% 0 4%;
          box-sizing: border-box;
          margin: 26px 0 14px 0;
          .slider-quota{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #B4B4B4;
            font-size: 20px;
            font-weight: 600;
          }
        }
        .calculate-detail-box{
          width: 100%;
          min-height: 200px;
          background-color: #fff;
          border-radius: 12px;
          border: 1px solid #E9EDEB;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 40px 0 44px 0;
          .detail-left, .detail-right{
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .detail-tip{
              font-size: 16px;
              font-weight: 600  ;
              color: #B4B4B4;
              margin-bottom: 18px;
            }
            .period, .rate{
              width: 80%;
              // height: 80px;
              // line-height: 80px;
              padding: 20px 0;
              background: #F6FBE9;
              border-radius: 40px;
              border: 1px solid #D0F9E5;
              text-align: center;
              color: #084032;
              font-size: 24px;
              font-weight: 600;
            }
          }
        }
        .calculate-bottom-tip{
          width: 100%;
          text-align: center;
          font-weight: 600;
          color: #28543F;
          font-size: 22px;
        }
        .calculate-btn-box{
          width: 100%;
          min-height: 200px;
          background: #20332B;
          position: absolute;
          left: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          .calculate-btn{
            width: 76%;
            height: 100px;
            line-height: 100px;
            text-align: center;
            font-size: 30px;
            font-weight: 600;
            background: #B3E872;
            border-radius: 52px;
            cursor: pointer;
            animation: btnSize 2s infinite;
            animation-delay: 1s;
          }
          @keyframes btnSize {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.1);
            }
            100% {
              transform: scale(1);
            }
          }
        }
      }
    }
  }
  .content-recommend{
      width: 100%;
      margin: 96px 0 120px 0;
      .recommend-item{
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        img{
          width: 26px;
          height: 26px;
          margin-right: 14px;
        }
        div{
          font-size: 18px;
          font-weight: 600;
          color: #fff;
          line-height: 29px;
        }
      }
  }
  .calculate-down{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
      width: 40%;
      cursor: pointer;
    }
  }
  .home-serve{
    width: 100%;
    background: url("../assets/img/home/home-banner.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding: 60px 10% 80px 10%;
    box-sizing: border-box;
    // .el-col{
    //  padding: 0 50px !important; 
    // }
    .serve-tip{
      width: 60%;
      font-size: 60px;
      color: #28543F;
      font-weight: 600;
    }
    .serve-item-box{
      .grid-content{
        .item-card{
          width: 406px;
          height: 150px;
          background: #B3E872;
          border-radius: 79px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 50px 0 40px;
          box-sizing: border-box;
          font-size: 40px;
          font-weight: bold;
          line-height: 40px;
          margin: 70px 0 50px 0;
          img{
            width: 116px;
            margin-right: 30px;
          }
        }
        .grid-bottom-tip{
          width: 100%;
          color: #28543F;
          font-size: 24px;
        }
      }
    }
  }

  .business-data{
    width: 100%;
    min-height: 320px;
    background: #fff;
    position: relative;
    .business-item-box{
      max-width: 1580px;
      margin: 0 auto;
      .business-grid-content{
        width: 300px;
        min-height: 300px;
        padding: 70px 10px 0 40px;
        box-sizing: border-box;
        position: relative;
        .num, .unit{
          font-size: 38px;
          font-weight: bold;
          line-height: 38px;
        }
        .data{
          font-size: 12px;
          font-weight: 600;
          margin: 50px 0 28px 0;
        }
        .bottom-line{
          width: 126px;
          height: 7px;
          background: #B3E872;
        }
      }
    }
    .business-grid-content:after {
        content: "";
        display: block;
        position: absolute;
        right: 2px;
        top: 38%;
        height: 40%;
        width: 2px;
        background-color: #F1F1F1;
    }
    :last-child > .business-grid-content:after{
        height: 0 !important;
    }
  }

  .tutorial{
    width: 100%;
    min-height: 500px;
    background: #fff;
    background: url("../assets/img/home/tutorial-bg.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 70px 0 0 0;
    .tutorial-item-box{
      max-width: 1400px;
      margin: 0 auto;
      .tutorial-grid-content{
        display: flex;
        justify-content: center;
        margin-bottom: 100px;
        .tutorial-left{
          width: 276px;
          height: 269px;
        }
        .tutorial-right{
          width: 276px;
          height: 291px;
          background: #B3E872;
          border-radius: 8px 8px 100px 8px;
          position: relative;
          margin-top: 30px;
          color: #1E6642;
          padding: 38px 16px 0 22px;
          box-sizing: border-box;
          .right-tip{
            width: 88px;
            height: 88px;
            position: absolute;
            left: 34%;
            top: -58px;
          }
          .right-top{
            width: 230px;
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 15px;
          }
          .right-text{
            width: 186px;
            font-size: 14px;
          }
        }
        .bg-darks{
          background: #28543F;
          color: #B3E872;
        }
      }
    }
  }

  .loan-wrap{
    width: 100%;
    min-height: 582px;
    background: url("../assets/img/home/loan-bg.png") no-repeat;
    background-size: 100% 100%;
    background-color: #084032;
    overflow: hidden;
    .loan-item-box{
      max-width: 1400px;
      margin: 0 auto;
      padding-top: 88px;
      box-sizing: border-box;
      .loan-grid-content{
        .loan-grid-head{
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 40px;
          img{
            width: 88px;
            height: 88px;
            margin-right: 18px;
          }
          div{
            font-size: 30px;
            font-weight: bold;
            color: #F2FFF9;
          }
        }
        .loan-item{
          width: 484px;
          height: 80px;
          background: #fff;
          border-radius: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 12px 0 40px;
          box-sizing: border-box;
          margin: 0 auto 30px auto;
          .loan-item-title{
            font-weight: 600;
            font-size: 16px;
          }
          .loan-item-text{
            width: 216px;
            height: 60px;
            background: #B3E872;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            font-weight: 600;
          }
        }
      }
    }
  }

  .feedback-wrap{
    width: 100%;
    min-height: 440px;
    background: #fff;
    background: url("../assets/img/home/fk-bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 60px 0 60px 0;
    box-sizing: border-box;
    .feedback-card{
      max-width: 1324px;
      min-height: 378px;
      margin: 0 auto;
      background: url("../assets/img/home/bubble.png") no-repeat;
      background-size: 100% 100%;
      margin-bottom: 20px;
      position: relative;
      img{
        max-width: 560px;
        position: absolute;
        right: 5px;
        top: 20px;
      }
      .feedback-carousel{
        width: 100%;
        padding: 40px 100px;
        box-sizing: border-box;
        font-size: 23px;
        .feedback-text{
          line-height: 33px;
        }
        .feedback-prople{
          font-weight: bold;
          color: #2AA669;
          margin-top: 30px;
        }
      }
    }
    .feedback-bottom{
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 50px;
      img{
        width: 50px;
        height: 50px;
        margin-right: 20px;
      }
    }
  }

  .lender-wrap{
    width: 100%;
    min-height: 250px;
    background: #fff;
    padding: 60px 0 60px 0;
    box-sizing: border-box;
    .lender-card{
      max-width: 1324px;
      min-height: 250px;
      margin: 0 auto;
      background: url("../assets/img/home/lender-bg.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 170px 0 120px;
      box-sizing: border-box;
      .rp-img{
        width: 130px;
        height: 155px;
      }
      .lender-center{
        text-align: center;
        font-weight: 600;
        img{
          width: 88px;
          height: 27px;
          margin-bottom: 4px;
        }
        .center-t{
          font-size: 40px;
        }
        .center-c{
          font-size: 22px;
          margin-bottom: 6px;
        }
        .center-b{
          display: flex;
          align-items: center;
          font-size: 14px;
          div{
            padding: 0 18px;
            border-right: 1px solid #000;
          }
          div:last-child{
            border: none;
          }
        }
      }
      .lender-right{
        font-size: 14px;
        font-weight: 600;
        .lender-btn{
          width: 247px;
          height: 60px;
          background: #B3E872;
          box-shadow: 0px 4px 0px 0px #28543F;
          border-radius: 43px;
          text-align: center;
          line-height: 60px;
          cursor: pointer;
          font-size: 20px;
          font-weight: 600;
          margin-top: 40px;
        }
      }
    }
  }

  .m-lender-wrap{
    width: 100%;
    min-height: 200px;
    background: url("../assets/img/home/m-lender-bg.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
    .m-lender-card{
      padding: 22px 10% !important;
      .m-lender-title{
        font-size: 28px;
        font-weight: 600;
      }
      .m-lender-tip{
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .m-lender-center{
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          margin-bottom: 10px;
          div{
            padding: 0 18px;
            border-right: 1px solid #000;
          }
          div:last-child{
            border: none;
          }
      }
      .m-lender-bottom{
        font-size: 12px;
        margin-bottom: 10px;
      }
      .m-lender-btn{
        width: 80%;
        height: 46px;
        line-height: 46px;
        background: #B3E872;
        box-shadow: 0px 2px 0px 0px #28543F;
        border-radius: 19px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        margin: 0 auto;
      }
    }
  }

  .partner-wrap{
    width: 100%;
    min-height: 250px;
    background: #fff;
    text-align: center;
    .partner-tip{
      font-size: 50px;
      font-weight: bold;
    }
  }
  .auth-wrap{
    width: 100%;
    min-height: 250px;
    .auth-card{
      max-width: 1224px;
      min-height: 250px;
      border-top: 1px solid #F6F6F6;
      margin: 0 auto;
      text-align: center;
      font-size: 16px;
      color: #28543F;
      font-weight: bold;
      padding: 46px 0 100px 0;
      box-sizing: border-box;
      img{
        margin: 20px 0 14px 0
      }
      .kan{
        max-width: 250px;
        background: rgb(246, 246, 246);
        border-radius: 20px;
        padding: 10px 20px;
      }
      .cek-text{
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
        display: none;
      }
    }
  }

  .m-auth-wrap{
    width: 100%;
    padding: 10px 22px 20px 22px;
    box-sizing: border-box;
    .m-auth-item{
      width: 100%;
      padding: 16px 20px;
      background: #F6F6F6;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #28543F;
      margin-bottom: 10px;
      .m-auth-left{
        .m-auth-title{
          font-size: 12px;
          font-weight: bold;
          margin-bottom: 6px;
        }
        .m-auth-text{
          font-size: 12px;
          cursor: pointer;
          display: none;
        }
      }
      .m-auth-right{
        img{
          width: 100%;
        }
        .m-kan{
          max-width: 220px;
        }
      }
    }
  }
}
</style>
